import React, { PureComponent } from 'react';
const config = require('../../config').default;
import Tappable from 'react-tappable';
import styles from './styles';

class DropDownItem extends PureComponent {
  render() {
    const { dropDownType, value, action, greyedOut } = this.props;
    return (
      <div className="ith_dropDownItemWrapper">
        {dropDownType === 'provider' &&
          <Tappable onTap={() => action(value)}>
            <div className="ith_dropdownEmployee" style={{ background: greyedOut ? '#eee' : '' }}>
              <div className="ith_employeeNameAvatar">
              {
                value.avatarFileName ?
                  <img className="ith_employeeAvatar" src={value.avatarFileName} alt="avatar" />
                  : <img className="ith_employeeAvatar" src={`${config.api.consumerUrl}/static/imgs/avatar.png`} alt="avatar" />
              }
                <p className="ith_dropdownText" style={{ color: greyedOut ? '#ccc' : '' }}>{value.User.firstName} {value.User.lastName}</p>
              </div>
            </div>
          </Tappable>
        }
        {dropDownType === 'service' &&
          <Tappable onTap={() => action(value)}>
            <div className="ith_dropDownService" style={{ background: greyedOut ? '#eee' : '' }}>
              <p className="ith_dropdownText" style={{ color: greyedOut ? '#ccc' : '' }}>{value.name + " " + value.duration + " min"}</p>
              <p className="ith_dropdownPrice" style={{ color: greyedOut ? '#ccc' : '' }}>{"CHF " + Math.round((value.ppu * value.count) / 0.05) * 0.05}</p>
            </div>
          </Tappable>
        }
        {dropDownType === 'language' &&
          <Tappable onTap={e => this.props.setChosenLanguage(this.props.id, this.props.language)}>
            <span className="ith_language">{this.props.language}</span>
          </Tappable>
        }
        <style jsx>{styles}</style>
      </div>
    );
  }
}
export default DropDownItem;
