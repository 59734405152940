import css from 'styled-jsx/css';
import colors from '../../constants/colors';

export default css`
  .ith_dropDownItemWrapper {
    height: 60px;
  }

  .ith_dropdownEmployee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    max-width: 380px;
    width: 100%;
    padding: 5px 10px 5px 10px;
    border-radius: 6.2px;
    box-shadow: 0 13px 17px -13px #e5eced;
    border: solid 1.2px #e5eced;
    background-color: #ffffff;
    position: absolute;
  }

  .ith_dropdownEmployee:hover {
    background-color: #edeff2;
  }

  .ith_employeeNameAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .ith_employeeAvatar {
    border-radius: 50%;
    height: 100%;
    margin-right: 10px;
  }

  .ith_dropdownText {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    color: #273d52;
  }

  .ith_dropdownPrice {
    min-width: 70px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    color: #273d52;
  }

  .ith_dropDownService {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 5px 10px 5px 10px;
    border-radius: 6.2px;
    box-shadow: 0 13px 17px -13px #e5eced;
    border: solid 1.2px #e5eced;
    background-color: #ffffff;
  }

  .ith_dropDownService:hover {
    background-color: #edeff2;
  }

  #ith_therapy {
    width: 100%;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    color: #273d52;
    float: left;
    margin-left: 5%;
    margin-top: 2%;
  }

  #ith_therapyDesc {
    width: 100%;
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #111111;
    margin-top: 2%;
    margin-left: 5%;
    float:left;
  }

  .ith_dropDownLanguage {
    border-top: solid 1px #e5eced;
    border-bottom: solid 1px #e5eced;
    background-color: #ffffff;
    cursor: pointer;
    border-radius: 6.2px;
    z-index: 999999999999;
    text-align: center;
  }

  .ith_language {
    font-family: Roboto;
    font-size: 14px;
    color: #E74C3C !important;
    font-weight: 400 !important;
    color: ${colors.headerText};
  }

@media (max-width:1350px) {
  #ith_therapy {
    font-size: 16px;
  }
}

@media (max-width:1150px) {
}

@media (max-width:950px) {
  #ith_therapy {
    margin-left: 5%;
    float:none;
  }

  #ith_therapyDesc {
    margin-left: 5%;
  }
}
`;
